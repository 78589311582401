import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import api, { Response } from "superagent";
import {
    Grid,
    Button,
    Typography,
    LinearProgress, Tooltip,
} from "@material-ui/core";
import { Theme, makeStyles } from "@material-ui/core/styles";
import { CloudUpload, Edit } from "@material-ui/icons";
import {useActions} from "../../../actions";
import * as PaymentActions from "../../../actions/payment";
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import {isAdmin} from "../../navigation/AdminNavigation";
import {useSelector} from "react-redux";
import {RootState} from "../../../reducers";

const useStyles = makeStyles((theme: Theme) => ({
    gridList: {
        flexWrap: "nowrap",
        transform: "translateZ(0)",
    },
    title: {
        color: theme.palette.primary.light,
    },
    titleBar: {
        background:
            "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
    },
    dashed: {
        borderStyle: "dashed",
        color: "#cccccc",
        backgroundColor: "#eeeeee",
        // height: '50px'
    },
    mOne: {
        margin: theme.spacing(1),
    },
    mLeftOne: {
        marginLeft: theme.spacing(1),
    },
    mRightTwo: {
        marginRight: theme.spacing(2),
    },
    barColorPrimary: {
        backgroundColor: "#179917",
    },
    colorPrimary: {
        backgroundColor: "#cfcfcf",
    },
    root: {
        height: "10px",
        marginTop: "-4px !important",
    },
}));

export default function PaymentFileUploader({
                                         dirName,
                                         fileType,
                                         width,
                                         height,
                                         getFileSize,
                                         iconOnly = false,
                                         fileName,
                                         setFileName,
                                        title = "Upload File",
                                        inputPops = {}
                                     }: any) {
    const [files, setFiles] = useState([]);
    const [validate, setValidate] = useState(false);
    const [uploadingPercentage, setUploadingPercentage] = useState(0);
    const [fileUrl, setFileUrl] = useState();
    const {user} = useSelector((state: RootState) => state.auth);

    const classes = useStyles();
    const payment = useActions(PaymentActions);
    const { getRootProps, getInputProps } = useDropzone({
        accept: fileType || "image/*",
        onDrop: acceptedFiles => {
            acceptedFiles.map(async (file) => {
                const preview: string = URL.createObjectURL(file);
                if (width && height) {
                    const i = new Image();
                    i.onload = () => {
                        let reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = async () => {
                            if (i.width > width || i.height > height) {
                                setValidate(true);
                            } else {
                                setValidate(false);
                                setFiles(file as any);
                                if (getFileSize) {
                                    getFileSize(file.size);
                                }
                                await handleSendToS3(file as any);
                            }
                        };
                    };
                    i.src = preview;
                } else {
                    setFiles(file as any);
                    if (getFileSize) {
                        getFileSize(file.size);
                    }
                    await handleSendToS3(file as any);
                }
                return Object.assign(file, { preview });
            });
        },
    });

    interface fileProps {
        name: any,
        preview: any
    }

    interface awsResp extends Response {
        req?: {
            url: string
        }
    }

    const handleSendToS3 = async (file: any) => {
        const parts = file.path.split('.');
        const fileExtension = parts[parts.length - 1];
        const filePayload = {
            policyNumber: dirName,
            contentType: file.type || "image/jpg",
            fileExtension: fileExtension,
            title: fileName
        };
        const response =  await payment.getSaveSignedUrl(filePayload);
        let signedUrl = atob(response.preSignedURL);
        api
            .put(signedUrl)
            .send(file)
            .on("progress", (e) => {
                setUploadingPercentage(Number(e.percent));
            })
            .then((res: awsResp) => {
                console.log("Respnse AWS ===>", res);
                setFileName(response.fileName);
            })
            .catch(console.error);
    };

    const uploadingBar = () => (
        <LinearProgress
            classes={{
                colorPrimary: classes.colorPrimary,
                barColorPrimary: classes.barColorPrimary,
                root: classes.root,
            }}
            variant="determinate"
            value={uploadingPercentage}
        />);

    useEffect(() => () => {
        files.length > 0 && files.forEach((file: fileProps) => URL.revokeObjectURL(file.preview));
    }, [files.length]);

    return (
        <Grid container justify="center">
            <Grid item xs={12} sm={12} md={12} {...getRootProps()}>
                <div className={iconOnly ? "editIcon mt15" : "fileUpload mt15 "}>
                    {
                        (!iconOnly && fileUrl && !validate) && ((!width && !height) &&
                            <Edit className="floatLeft"/>)
                    }
                    <input disabled={isAdmin(user)} {...inputPops}  {...getInputProps()} />
                    <div className="linearProgress">
					<span className="linearProgressInput">
						{
                            uploadingPercentage > 0 && uploadingPercentage < 100 &&
                            uploadingBar()
                        }
					</span>

                    </div>
                    <div className="textCenter DocumentUpload">
                        {
                            iconOnly
                                ? <Tooltip title="Edit">
                                    <Edit className={classes.mRightTwo}/>
                                </Tooltip>
                                : <React.Fragment>
                                    <div className="floatLeft">
                                        <LibraryAddIcon className="floatLeft mr15 mt20 ml5"/>
                                        <Typography align="left" className="floatLeft mt20">{title}</Typography>
                                    </div>
                                    {/*<div className="floatRight">*/}
                                    {/*    <Button*/}
                                    {/*        disabled*/}
                                    {/*        className="mt20"*/}
                                    {/*        type="submit"*/}
                                    {/*        variant="contained"*/}
                                    {/*        color="primary"*/}
                                    {/*    >*/}
                                    {/*        Browse Files*/}
                                    {/*    </Button>*/}
                                    {/*</div>*/}
                                    {
                                        validate &&
                                        <strong className="w100 floatLeft" style={{ color: "red" }}>Please check Width
                                            or Height of File</strong>
                                    }
                                </React.Fragment>
                        }
                    </div>
                </div>
            </Grid>
        </Grid>
    );
}