import React from "react";
import {
    Grid,
    Typography,
    Card,
    CardContent,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction, IconButton, Tooltip, Box
} from "@material-ui/core";
import ReactPlayer from "react-player";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import InfoIcon from "@material-ui/icons/Info";
import NumberFormat from "react-number-format";
import Assumptions from "../Assumptions";
import TabNavigation from "../TabNavigation";
import { LOGO_BASE_URL } from "../../../../../../constants";
import {maskCurrency} from "../../../../../../utils/commonUtils";

function EstimationTabOne(props: any) {
    const {
        callingFrom,
        estimationData,
        isLead,
        lifeTimeDeathBenefitSeeking,
        responseData,
        setTabValue,
        strategyName,
        strategySlug,
        tag,
    } = props;
    let videoName = "kai-zen-strategies-how-contributions-works-animation.mp4";
    if (strategySlug === "trizen" || strategySlug === "esd") {
        videoName = "tri-zen-strategies-how-contributions-works-animation.mp4";
    }
    const btnVideoUrl: string = `${LOGO_BASE_URL}estimation/${videoName}`;
    const {s3rootkey ,stress, common, illustration} = responseData;

    const contributionamount:number = stress && stress.contributionamount || 0;
    const contributionamountFiveYears:number = (stress && stress.contributionamount) * 5 || 0;
    let incomeageto:number = common && common.incomeageto || 0;
    let incomeagefrom:number = common && common.incomeagefrom || 0;
    let income:number = illustration && illustration.income || 0;
    let totalincome:number = illustration && illustration.totalincome || 0;
    let irrnetdbandincomeage90:number = illustration && illustration.data && illustration.data.irrnetdbandincomeage90 || 0;
    let deathbenefitlessloanyear1 = illustration && illustration.data && illustration.data.deathbenefitlessloanyear1 || 0;
    let deathbenefitlessloanage90 = illustration && illustration.data && illustration.data.deathbenefitlessloanage90 || 0;

    const paymentFrequency = common && common.paymentfrequency || "Annual";
    let contributionAmount = common && common.contributionamount;
    //If Employer Remove that from total
    if (common && common.contributionamountemployer) {
        contributionAmount = contributionAmount - common.contributionamountemployer;
    }
    let contributionText: any = false;
    if (paymentFrequency === "Monthly") {
        contributionText = `Monthly Contribution: ${maskCurrency((contributionAmount/12) + "")}`;
    }

    return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={(callingFrom === "ENROLLMENT") ? 12 : 7} xl={(callingFrom === "ENROLLMENT") ? 12 : 7}>
                        <Grid container>
                            <Grid item xs={12}>
                                {contributionText ? <Typography variant="body1" component="strong" className="mt20 mb0 floatLeft w100">
                                        {contributionText}
                                </Typography> : ""}
                                <Typography variant="body1" component="strong" className="mt30 mb20 floatLeft w100">
                                    Policy funding (
                                    <NumberFormat
                                            value={contributionamount}
                                            displayType={"text"}
                                            decimalScale={0}
                                            thousandSeparator={true}
                                            prefix={"$"}/>
                                    &nbsp;x 5 years =&nbsp;
                                    <NumberFormat
                                        value={contributionamountFiveYears}
                                        displayType={"text"}
                                        decimalScale={0}
                                        thousandSeparator={true}
                                        prefix={"$"}/>)
                                </Typography>
                                <Typography variant="caption" align="left" className="floatLeft w100 mb40">Your contributions are used to purchase death
                                    benefit protection that can provide cash in the event of a chronic or terminal
                                    illness. The excess funds have the potential to accumulate and grow over time, which
                                    can be used to help supplement your retirement.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="body1" component="strong" className="mb15 floatLeft w100">
                                    Benefits and distributions by age</Typography>
                                <Typography variant="caption" className="floatLeft mr30"><FiberManualRecordIcon
                                        className="colorYellow floatLeft"
                                        style={{ marginTop: "-4px" }}/> Protection</Typography>
                                {
                                    !lifeTimeDeathBenefitSeeking &&
                                    <Typography variant="caption" className="floatLeft"><FiberManualRecordIcon
                                            className="colorBlue floatLeft"
                                            style={{marginTop: "-4px"}}/> Accumulation</Typography>
                                }
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <div className="returnRateBox">
                                    <Typography variant="caption" className="f14 w100"><b>Internal Rate of
                                        Return</b></Typography>
                                    <NumberFormat value={irrnetdbandincomeage90*100}
                                                  displayType={"text"}
                                                  thousandSeparator={false} suffix={"%"}
                                                  decimalScale={2}
                                                  renderText={value => <Box className="f18 textHighLight">{value} <Tooltip arrow
                                                                                                                color="primary"
                                                                                                                title="Internal Rate of Return (IRR) measures the return on an investment, net of taxes and costs. An IRR can be useful for comparing investments with similar risk profiles. This IRR estimate includes residual death benefits assuming the insured under the IUL insurance policy dies at age 90.">
                                                      <IconButton aria-label="Info">
                                                          <InfoIcon/>
                                                      </IconButton>
                                                  </Tooltip></Box>}/>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                <Card className="benefitsCard bgLightGray mt40 mb20">
                                    <CardContent className="benefitsCardContent">
                                        <List>

                                            <ListItem disableGutters={true}>
                                                <ListItemAvatar>
                                                    <FiberManualRecordIcon
                                                            style={{ marginTop: "-23px", float: "left" }}
                                                            className="colorYellow"/>
                                                </ListItemAvatar>
                                                <ListItemText
                                                        primary="Initial death benefit protection (net of loan)"
                                                        secondary="Access to living benefit riders"/>
                                                <ListItemSecondaryAction>
                                                    <Typography variant="subtitle1" className="mb0 valueText">
                                                        <NumberFormat
                                                                value={deathbenefitlessloanyear1}
                                                                displayType={"text"}
                                                                decimalScale={0}
                                                                thousandSeparator={true} prefix={"$"}
                                                                renderText={value => <Box className="f18 floatLeft"
                                                                >{value}</Box>}/>
                                                        <Tooltip
                                                                color="primary"
                                                                title="Initial death benefit." arrow>
                                                            <IconButton aria-label="Info">
                                                                <InfoIcon/>
                                                            </IconButton>
                                                        </Tooltip></Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                                {
                                    !lifeTimeDeathBenefitSeeking &&
                                    <Card className="benefitsCard bgLightGray mt40 mb20">
                                        <CardContent className="benefitsCardContent">
                                            <List>
                                                <ListItem disableGutters={true}>
                                                    <ListItemAvatar>
                                                        <></>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={
                                                        <React.Fragment>
                                                            <Typography
                                                                    variant="body1"
                                                                    component="strong"
                                                                    className="mb0"
                                                            >
                                                                Tax Free Distributions Between Age {incomeagefrom} and {incomeageto}
                                                            </Typography>
                                                        </React.Fragment>
                                                    }/>
                                                </ListItem>
                                                <ListItem disableGutters={true}>
                                                    <ListItemAvatar>
                                                        <FiberManualRecordIcon
                                                                style={{marginTop: "-23px", float: "left"}}
                                                                className="colorBlue"/>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                            primary="Begin potential tax-free distributions"
                                                            secondary="when using policy loans"/>
                                                    <ListItemSecondaryAction>
                                                        <Typography variant="subtitle1" className="mb0 valueText">
                                                            <NumberFormat
                                                                    value={income}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true} prefix={"$"}
                                                                    renderText={value => <Box
                                                                            className="f18 floatLeft">{value}</Box>}/> /
                                                            yr<Tooltip arrow
                                                                       color="primary"
                                                                       title="This is an estimation of policy distributions taken annually.">
                                                            <IconButton aria-label="Info">
                                                                <InfoIcon/>
                                                            </IconButton>
                                                        </Tooltip></Typography>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </List>
                                        </CardContent>
                                    </Card>
                                }
                                <Card className="benefitsCard bgLightGray mt40 mb20">
                                    <CardContent className="benefitsCardContent">
                                        <List>
                                            <ListItem disableGutters={true}>
                                                <ListItemAvatar>
                                                    <></>
                                                </ListItemAvatar>
                                                <ListItemText primary={
                                                    <React.Fragment>
                                                        <Typography
                                                                variant="body1"
                                                                className="mb0"
                                                                component="strong"
                                                        >
                                                            Age {incomeageto} (based off ages set above)
                                                        </Typography>
                                                    </React.Fragment>
                                                }/>
                                            </ListItem>
                                            {
                                                !lifeTimeDeathBenefitSeeking &&
                                                <ListItem disableGutters={true}>
                                                    <ListItemAvatar>
                                                        <FiberManualRecordIcon style={{float: "left"}}
                                                                               className="colorBlue"/>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                            primary={`Cumulative distributions taken from age ${incomeagefrom}-${incomeageto}`}
                                                    />
                                                    <ListItemSecondaryAction>
                                                        <Typography variant="subtitle1" className="mb0 valueText">
                                                            <NumberFormat
                                                                    value={totalincome}
                                                                    displayType={"text"}
                                                                    thousandSeparator={true} prefix={"$"}
                                                                    renderText={value => <Box className="f18 floatLeft"
                                                                    >{value}</Box>}/>
                                                            <Tooltip
                                                                    arrow
                                                                    color="primary"
                                                                    title="This is the tota l amount of distributions taken through age 90.">
                                                                <IconButton aria-label="Info">
                                                                    <InfoIcon/>
                                                                </IconButton>
                                                            </Tooltip></Typography>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            }
                                            <ListItem disableGutters={true}>
                                                <ListItemAvatar>
                                                    <FiberManualRecordIcon style={{ float: "left" }}
                                                                           className="colorYellow"/>
                                                </ListItemAvatar>
                                                <ListItemText
                                                        primary="Remaining death benefit for beneficiaries net of all loan repayments."
                                                />
                                                <ListItemSecondaryAction>
                                                    <Typography variant="subtitle1" className="mb0 valueText">
                                                        <NumberFormat
                                                                value={deathbenefitlessloanage90}
                                                                displayType={"text"}
                                                                decimalScale={0}
                                                                thousandSeparator={true} prefix={"$"}
                                                                renderText={value => <Box className="f18 floatLeft"
                                                                                          >{value}</Box>}/>
                                                        <Tooltip
                                                                arrow
                                                                color="primary"
                                                                title="The amount paid to a beneficiary upon the death
                                                                of an insured person. Death benefits start out higher
                                                                depending on your age and health. The amount shown here
                                                                 is the remaining amount at age 90 after policy
                                                                 distributions.">
                                                            <IconButton aria-label="Info">
                                                                <InfoIcon/>
                                                            </IconButton>
                                                        </Tooltip></Typography>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={12} lg={(callingFrom === "ENROLLMENT") ? 12 : 5} xl={(callingFrom === "ENROLLMENT") ? 12 : 5}>
                        <ReactPlayer
                                controls={false}
                                playing={true}
                                loop={true}
                                autoPlay={true}
                                volume={0}
                                muted={true}
                                url={btnVideoUrl}
                                width='100%'
                                height='800px'
                                playsinline={true}
                                className="tabTwoVideo"
                        />
                    </Grid>

                    <span className="hLine"/>
                    <TabNavigation
                            next={{title:"Next: Comparisons", visibility: true, setTabValueNext: setTabValue, tabValNext:"three" }}
                            prev={{title:"Prev: Contribution Totals", visibility: true, setTabValuePrev: setTabValue, tabValPrev:"one"}}
                    />

                </Grid>
                {
                    !isLead &&
                    <Assumptions key={s3rootkey} strategyName={strategyName} strategySlug={strategySlug} estimationData={estimationData} common={common} callingFrom={callingFrom} tag={tag} />
                }
            </>
    );
}

export default EstimationTabOne;