import React, { useState } from "react";
import { Divider, Typography } from "@material-ui/core";
import logoAgent from "../styles/images/logoAgent.png";
import logoAdmin from "../styles/images/logoAdmin.png";
import logoImo from "../styles/images/logo-IMO.png";
import logoBank from "../styles/images/logo-Bank.png";
import logoTrustee from "../styles/images/logo-Trustee.png";
import logoCarrier from "../styles/images/logo-Carrier.png";
import logoClient from "../styles/images/logo-ilia.png";
import niwLogo from "../styles/images/niwLogo.png";
import logoilia from "../styles/images/logoilia.png";
import pbg from "../styles/images/pbg.png";
import {AccountType, LOGO_BASE_URL, isServicingAccessible, StrategySlugEnum} from "../constants";
import logoAggregator from "../styles/images/aggregatorLogo.png";
import {
    AdminNavigation,
    AgentNavigation,
    AssociationClientNavigation,
    BankNavigation,
    ClientNavigation,
    GroupNavigation,
    ImoNavigation,
    TrusteeNavigation,
    AggregatorNavigation
} from "../components/navigation";
import { useSelector } from "react-redux";
import { RootState } from "../reducers";
import StrategyName from "./client/StrategyName";
import CarrierNavigation from "./navigation/CarrierNavigation";
import { isNfIulCase } from "../utils/commonFunc";
import { history } from "../configureStore";
import { checkIsReviewDetailRoute } from "../pages/clientServicing/utils";

type Props = {
    user: any,
    handleNavClick: Function,
    className: string,
    SetIsEstimation: Function
}
export default function CustomNavigation(props: Props) {
    const { user: { company }, SetIsEstimation } = props;
    const {
        user: { role, accessType, defaultStatergyLogo },
        user
    } = useSelector((state: RootState) => state.auth);
    const client = user && user.client;

    const [reBrandLogo, setReBrandLogo] = useState<string>("");
    const [companyLogo, setCompanyLogo] = useState<string>("");
    const [companyName, setCompanyName] = useState<string>("");
    const [strategyName, setStrategyName] = useState<string>("Kai-Zen");
    const [strategyLogo, setStrategyLogo] = useState<any>(defaultStatergyLogo);

    const isServiceDashboardAccessible = user && user.inviteStatus && isServicingAccessible(user) || false;
    let strategySlug: any = StrategySlugEnum.NfIUL;

    React.useEffect(() => {
        if (client) {
            setStrategyLogo((client.strategy && client.strategy.logo) || strategyLogo);
            setStrategyName((client.strategy && client.strategy.name) || strategyName);
            strategySlug = (client.strategy && client.strategy.slug) || strategySlug;
        } else if (accessType === "client" && (user.role === 1 || user.role === 2 || user.role === 3 || user.role === 4 || user.role === 15)) {
            setStrategyName((user.demoStrategy && user.demoStrategy.name) || strategyName);
            setStrategyLogo((user.demoStrategy && user.demoStrategy.logo) || strategyLogo);
            strategySlug = (user.demoStrategy && user.demoStrategy.slug) || strategySlug;
        }
    }, [client, user]);
    const isNfIul = isNfIulCase((user.client && user.client.strategy && user.client.strategy.id) as number, strategySlug);

    React.useEffect(() => {
        if (props.user.client && props.user.client.strategy) {
            if (props.user.client && props.user.client.imo && props.user.client.imo.reBrand && props.user.client.strategy && props.user.client.strategy.reBrand === 2 && props.user.client.strategy.reBrandLogo) {
                setReBrandLogo(LOGO_BASE_URL + props.user.client.strategy.reBrandLogo);
            }
            if (props.user.client.strategy && props.user.client.strategy.reBrand === 1 && props.user.client.strategy.reBrandLogo) {
                setReBrandLogo(LOGO_BASE_URL + props.user.client.strategy.reBrandLogo);
            }
            if (props.user.client.agent && props.user.client.agent.companyLogo) {
                setCompanyLogo(props.user.client.agent.companyLogo);
            }

            if (props.user.role === 5 && props.user.client.agent.user) {
                setCompanyName(props.user.client.agent.companyName);
            } else if (props.user.role === 6 && props.user.client && props.user.client.company) {
                setCompanyName(props.user.client.company.companyName);
                setCompanyLogo(props.user.client.company.user.image);
            }
        }
        if (props.user.accessType === "client" && user.role === 4) {
            if (props.user.agent && props.user.agent.companyLogo) {
                setCompanyLogo(props.user.agent.companyLogo);
            }
            if (props.user.agent && props.user.agent.companyName) {
                setCompanyName(props.user.agent.companyName);
            }
            if (props.user.agent && props.user.agent.imo && props.user.agent.imo.reBrand && props.user.demoStrategy && props.user.demoStrategy.reBrand === 2 && props.user.demoStrategy.reBrandLogo) {
                setReBrandLogo(LOGO_BASE_URL + props.user.demoStrategy.reBrandLogo);
            }
            if (props.user && props.user.demoStrategy && props.user.demoStrategy.reBrand === 1 && props.user.demoStrategy.reBrandLogo) {
                setReBrandLogo(LOGO_BASE_URL + props.user.demoStrategy.reBrandLogo);
            }
        }
        if (props.user.accessType === "client" && (user.role === 3 || user.role === 15)) {
            if (props.user && props.user.imo && props.user.imo.reBrand && props.user.demoStrategy && props.user.demoStrategy.reBrand === 2 && props.user.demoStrategy.reBrandLogo) {
                setReBrandLogo(LOGO_BASE_URL + props.user.demoStrategy.reBrandLogo);
            }
            if (props.user && props.user.demoStrategy && props.user.demoStrategy.reBrand === 1 && props.user.demoStrategy.reBrandLogo) {
                setReBrandLogo(LOGO_BASE_URL + props.user.demoStrategy.reBrandLogo);
            }
            if (user.role === 15) {
                let loggedInUser: any = user;
                let imo: null | any = null;
                if (loggedInUser.hasOwnProperty("ImoAdditionalMembers")) {
                    imo = loggedInUser.ImoAdditionalMembers && loggedInUser.ImoAdditionalMembers.imo;
                    if (imo && imo.reBrand && loggedInUser && loggedInUser.demoStrategy && loggedInUser.demoStrategy.reBrand === 2 && loggedInUser.demoStrategy.reBrandLogo) {
                        setReBrandLogo(LOGO_BASE_URL + props.user.demoStrategy.reBrandLogo);
                    }
                }
            }
        }

        if (props.user.accessType === "client" && (user.role === 1 || user.role === 2)) {
            if (user.demoStrategy && user.demoStrategy.reBrand && user.demoStrategy.slug === "kaizen") {
                setReBrandLogo(LOGO_BASE_URL + user.demoStrategy.reBrandLogo);
            }
        }
    }, [props.user]);

    return (
            <div key="roleBasedNavigation">
                {
                        (role === 4 || role === 3 || role === 1 || role === 2 || role === 15 || role === AccountType.Aggregator || role === AccountType.AggregatorMember) &&
                        accessType === "client" &&
                        <div key="clientNavigation">
                            <div className="logoWrap txtCenter ">
                                <div className="mt30">
                                    <img className="mt20" alt="Re Brand Logo" src={reBrandLogo || logoClient as string}/>
                                </div>
                                <Divider/>
                                <div className="clearFix"/>
                                <Typography variant="h5"> <img alt="" style={{ maxWidth: 200 }}
                                                               src={LOGO_BASE_URL + strategyLogo}/> <br/> Sponsored
                                    By</Typography>
                                <div className="clearFix"/>
                                {
                                    (role === 3 || role === 1 || role === 2 || role === 15 || role === AccountType.Aggregator || role === AccountType.AggregatorMember) && (user.demoStrategy && user.demoStrategy.reBrand && user.demoStrategy.slug === "kaizen") ?
                                            <Typography variant="h5" className="mt10">Company Logo Here</Typography> :
                                            ((role === 4 || role === 3 || role === 15) && !companyLogo) ?
                                                    <Typography variant="h5" className="mt10">Company Logo
                                                        Here</Typography> :
                                                    <img alt="" className="mt20 mb20 companyLogo"
                                                         src={companyLogo ? LOGO_BASE_URL + companyLogo : (user.demoLogo && (role === 1 || role === 2)) ? LOGO_BASE_URL + user.demoLogo : niwLogo as string}/>
                                }
                                <div className="clearFix"/>
                            </div>
                            <ClientNavigation user={props.user} handleNavClick={props.handleNavClick}
                                              SetIsEstimation={SetIsEstimation}/>
                        </div>
                }
                {
                        (role === 5 || role === 6) &&
                        <div key="clientNavigation">
                            <div className="logoWrap txtCenter ">
                                <img alt="" className="logo-" src={reBrandLogo || logoClient  as string}/>
                                <Divider/>
                                <div className="clearFix"/>
                                {
                                    !isServiceDashboardAccessible &&
                                        <>
                                            {
                                                (isNfIul) ?
                                                        <>
                                                            {
                                                                (strategyLogo) ?
                                                                        <>
                                                                            <img alt={strategyName}
                                                                                 src={LOGO_BASE_URL + strategyLogo} />
                                                                            <br />
                                                                            <Typography
                                                                                variant="h5">Sponsored By</Typography>
                                                                        </>
                                                                        :
                                                                        strategyName &&
                                                                        <Typography variant="h5"> <br /><strong>
                                                                            <StrategyName strategyName={strategyName} />
                                                                        </strong> <br /> Sponsored By </Typography>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <img alt={"Kai-Zen"}
                                                                 src={"https://niw-stage.s3.amazonaws.com/statregyLogo/XR2O5RX0.png"} />
                                                            <br />
                                                            <Typography variant="h5">Sponsored By</Typography>
                                                        </>
                                            }
                                    </>
                                //     <>
                                // {
                                //     (strategyLogo) ?
                                //             <>
                                //                 <img alt={strategyLogo} src={LOGO_BASE_URL + strategyLogo}/>
                                //                 <br/><Typography variant="h5">Sponsored By</Typography>
                                //             </>
                                //             :
                                //             strategyName &&
                                //             <Typography variant="h5"> <br/><strong> <StrategyName
                                //                     strategyName={strategyName}/> </strong>
                                //                 <br/>
                                //                 Sponsored By
                                //             </Typography>
                                // }
                                // </>
                            }
                                <div className="clearFix"/>
                                {
                                    !isServiceDashboardAccessible &&
                                <>
                                {
                                    companyLogo
                                            ? <img alt="" className="mt20 mb20" style={{ maxWidth: 200 }}
                                                   src={LOGO_BASE_URL + companyLogo}/>
                                            : (companyName)
                                                    ? <Typography variant="h5"><strong>{companyName}</strong></Typography>
                                                    : (reBrandLogo)
                                                            ? <img alt="" className="mt20 mb20" src={reBrandLogo}/>
                                                            : <Typography variant="h5">
                                                                <div className="clearFix"/>
                                                                <img alt="niwLogo" src={niwLogo as string}/>
                                                            </Typography>
                                }
                                </>
                                }
                                <div className="clearFix"/>
                            </div>
                            <ClientNavigation user={props.user} handleNavClick={props.handleNavClick}
                                              SetIsEstimation={SetIsEstimation} showLinks={!checkIsReviewDetailRoute(history.location)}/>
                        </div>
                }
                {
                        (role === 9 && accessType !== "client") &&
                        <div key="clientNavigation">
                            <div className="logoWrap txtCenter ">
                                <img alt="" className="logo" src={logoClient as string}/>
                                <Divider/>
                                <div className="clearFix"/>
                                <Typography variant="h5">
                                    {company &&
                                            <Typography variant="h5">{company.companyName}</Typography>
                                    }
                                </Typography>
                            </div>
                            <div className="clearFix"/>
                            <AssociationClientNavigation/>
                        </div>
                }
                {
                    (role === 12 || role === 17) &&
                        <div key="trustee avigation">
                            <img alt="" className="trustee logo" src={logoTrustee  as string}/>
                            <div className="clearFix"/>
                            <TrusteeNavigation handleNavClick={props.handleNavClick}/>
                        </div>
                }
                {
                        (role === 13) &&
                        <div key="bankNavigation">
                            <img alt="" className="bank logo" src={logoBank  as string}/>
                            <div className="clearFix"/>
                            <BankNavigation/>
                        </div>
                }
                {
                        ((role === 3 || role === 15) && accessType !== "client") &&
                        <div key="imoNavigation">
                            <img alt="" className="iMO logo" src={logoImo  as string}/>
                            <div className="clearFix"/>
                            <ImoNavigation handleNavClick={props.handleNavClick}/>
                        </div>
                }
                {
                        (role === 4 && accessType !== "client") &&
                        <div key="agentNavigation">
                            <img alt="" className="Agent logo" src={logoAgent  as string}/>
                            <div className="clearFix"/>
                            <AgentNavigation handleNavClick={props.handleNavClick}/>
                        </div>
                }
                {
                        ((role === 2 || role === 1) && accessType !== "client") &&
                        <div key="adminNavigation">
                            <img alt="" className="Admin logo" src={logoAdmin  as string}/>
                            <div className="clearFix"/>
                            <AdminNavigation role={role} handleNavClick={props.handleNavClick}/>
                        </div>
                }

                {
                        role === 11 &&
                        <div key="clientNavigation">
                            <div className="logoWrap txtCenter ">
                                <img alt="" className="logo" src={reBrandLogo || logoClient  as string}/>
                                <Divider/>
                                <div className="clearFix"/>
                                {(props.user.groupLinkUser && props.user.groupLinkUser.groupLink) &&
                                        <Typography variant="h5">
                                            <strong>{props.user.groupLinkUser.groupLink.productName}</strong>
                                        </Typography>
                                }
                                <div className="clearFix"/>
                                <Typography variant="h5" align="center"><img alt=""
                                                                             className="mt20 mb20"
                                                                             src={pbg as string}/></Typography>
                                <div className="clearFix"/>
                            </div>
                        </div>
                }
                {
                        role === 8 &&
                        <div key="groupNavigation">
                            <img alt="" className="iMO logo" src={logoilia  as string}/>
                            <div className="clearFix"/>
                            <div className="groupName">
                                <Typography variant="body1" align="right"
                                            className="f14 mr15">{company && company.companyName}</Typography>
                            </div>

                            <div className="clearFix"/>
                            <GroupNavigation handleNavClick={props.handleNavClick}/>
                        </div>
                }
                {
                        role === 16 &&
                        <div key="groupNavigation">
                            <img alt="" className="iMO logo" src={logoilia as string}/>
                            <div className="clearFix"/>
                            <div className="groupName">
                                <Typography variant="body1" align="right"
                                            className="f14 mr15">{user && user.groupSigners && user.groupSigners.company.companyName} Signer</Typography>
                            </div>

                            <div className="clearFix"/>
                            <GroupNavigation handleNavClick={props.handleNavClick}/>
                        </div>
                }
                {
                        (role === AccountType.Carrier) &&
                        <div key="trusteeNavigation">
                            <img alt="" className="trustee logo" src={logoCarrier as string}/>
                            <div className="clearFix"/>
                            <CarrierNavigation handleNavClick={props.handleNavClick}/>
                        </div>
                }
                {
			(role === AccountType.Aggregator || role === AccountType.AggregatorMember) && accessType !== "client" &&
                        <div key="aggregatorNavigation">
                            <img alt="" className="bank logo" src={logoAggregator as string}/>
                            <div className="clearFix"/>
                            <AggregatorNavigation handleNavClick={props.handleNavClick}/>
                        </div>
                }
                
            </div>
    );
}