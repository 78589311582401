import React from "react";
import {useSelector} from "react-redux";
import Card from "@material-ui/core/Card/Card";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import CardContent from "@material-ui/core/CardContent/CardContent";
import enrollmentHelp from "../../images/svg/sidebar_enrollment_help.svg";
import RightDrawer from "../../component/RightDrawer";
import Typography from "@material-ui/core/Typography/Typography";
import {RootState} from "../../../../../reducers";
import { isNfIulCase } from "../../../../../utils/commonFunc";

const EnrollmentHelp = () => {
    const {user} = useSelector((state: RootState) => state.auth);
    const isNfIul = isNfIulCase((user && user.client && user.client.strategy && user.client.strategy.id) as number, (user && user.client && user.client.strategy && user.client.strategy.slug) as string);

    if (isNfIul) {
        return (<></>);
    }

    return (
            <>
                <Card className="enrollmentHelpCard boxShadow w100 ">
                    <CardHeader
                            avatar={
                                <img src={enrollmentHelp as string} alt="#"/>
                            }
                            title={<React.Fragment>
                                <Typography
                                        component="span"
                                        variant="h5"
                                >
                                    Enrollment Help
                                </Typography>
                            </React.Fragment>}
                    />
                    <CardContent className="pd0">
                        <RightDrawer/>
                    </CardContent>
                </Card>
            </>
    );
};

export default EnrollmentHelp;