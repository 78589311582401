import React, { FunctionComponent, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import { FormInputs } from "../../../pages/approvedAgents/ApprovedAgentsPage";
import { Button } from "../../formInputs";
import { UploadCustomIllustration } from "./index";
import { ErrorCommon, ErrorHelper, FormWrapperProps } from "../../formikFormInputs";
import { contributionOptions, FormValues, incomeOptions, ProposalEnum, rateClassMappingObject, UCORIConstantKeyProps } from "./helper";
import {validObj} from "../../../utils/commonUtils";
import ContributionFrequency from "../../../pages/client/Estimation/V1/Components/ContributionFrequency";
import { RootState } from "../../../reducers";
import { useSelector } from "react-redux";
import { useActions } from "../../../actions";
import * as Actions from "../../../actions/estimation";
import { UploadedDocumentTable, reportsInterFace } from "./UploadedFilesTeble";
import StepFour from "../../../pages/client/Estimation/V1/Components/StepFour";
import NewEmployerContribution from "../../../pages/client/Estimation/V1/Components/NewEmployerContribution";
import { ATLANTIC_HEALTH } from "../../../constants";


interface FormProps extends FormWrapperProps {
    carrierId:number;
    contributionTypeConditionalFields: any;
    enrollmentId: any;
    errors: any;
    handleBlur: any;
    handleChange: any;
    handleRunIllustration: any;
    handleSubmit: any;
    illustrationFiles: any;
    incomeConditionalFields: any;
    isReportDataLoading: any;
    jVals: any;
    setErrors: any;
    setFieldValue: any;
    setTouched: any;
    setUploadCustomOrRunIllustration: any;
    touched: any;
    uploadCustomOrRunIllustration: any;
    UploadProposalFields: any;
    validateForm: any;
    values: FormValues;
}

const Form: FunctionComponent<FormProps> = (props) => {
    const DEFAULT_MIN_CON: number = 21350;
    const {
        carrierId,
        contributionTypeConditionalFields,
        enrollmentId,
        errors,
        handleBlur,
        handleChange,
        handleRunIllustration,
        handleSubmit,
        incomeConditionalFields,
        isReportDataLoading,
        jVals,
        setErrors,
        setFieldValue,
        setTouched,
        setUploadCustomOrRunIllustration,
        touched,
        uploadCustomOrRunIllustration,
        UploadProposalFields,
        validateForm,
        values,
    } = props;
    const commonFun = ({...jp}) => {
        [jp.name, jp.fileName] = [jp.fileName, jp.name];
        jp.path = enrollmentId;
        return jp;
    };


    const estimationActions = useActions(Actions);
    const userDetail = useSelector((root: RootState) => root.clientAccount);
    const minCon = useSelector((state: RootState) => state.minimumContribution) || DEFAULT_MIN_CON;
    let atlantic = (ATLANTIC_HEALTH.includes(userDetail && userDetail.client && userDetail.client.company && userDetail.client.company.companyLinkUrl  as any) && userDetail.role === 6)
    const onUploadCustomIllustration = () => setUploadCustomOrRunIllustration(ProposalEnum.UPLOAD_CUSTOM_ILLUSTRATION as UCORIConstantKeyProps);
    const autoValidateForm = async()=>{
        const formErrors = await validateForm(values)
        if(!formErrors  && Object.keys(formErrors).length < 1){
            return ;
        }
        let errorObj = {}
        Object.keys(formErrors).forEach((key) => {
            errorObj = {...errorObj, [key]: true}
        });
        setTouched(errorObj, true);
        setErrors(formErrors);
    } 
    const onRunIllustration = async () => {
        const formErrors = await validateForm(values)
        setUploadCustomOrRunIllustration(ProposalEnum.RUN_ILLUSTRATION as UCORIConstantKeyProps);
        if (!validObj(formErrors)) {
            return handleRunIllustration({values});
        }
        let errorObj = {}
        Object.keys(formErrors).forEach((key) => {
            errorObj = {...errorObj, [key]: true}
        });
        setTouched(errorObj, true);
        setErrors(formErrors);
    };

    React.useEffect(()=>{
        autoValidateForm()
    },[minCon]);
    React.useEffect(() => {
        if (userDetail && userDetail.client) {
            getMinContribution({
                issueAge: userDetail.client.issueAge,
                gender: userDetail.client.gender,
                carrierId: userDetail.client.carrierId,
                rateClass: rateClassMappingObject[values.healthRating] ? rateClassMappingObject[values.healthRating] : rateClassMappingObject['default'],
                frequency: values.contributionFrequency.toLowerCase()
            }).then();
        }
    }, [ values.healthRating, values.contributionFrequency])
    const getMinContribution = async (query: any) => {
        await estimationActions.getMinimumContribution(query);
    };
    console.log(values, "here is the values");
    return (
            <form id='upload-proposal-form' onSubmit={handleSubmit} className='mb15'>
                <Grid container spacing={2} className="mt10">
                    <FormInputs
                            fields={UploadProposalFields(values, setFieldValue)}
                            {...{errors, values, touched, handleChange, handleBlur}}
                    />
                    <Grid item xs={12}>
                        <Typography variant="caption" className="floatLeft w100 mb10">
                            Solve for one of the following:
                        </Typography>
                        <ToggleButtonGroup
                                aria-label="Solve for one of the following"
                                color="primary"
                                exclusive
                                id="contributionType"
                                onChange={(_event: any, value: string) => {
                                    if (value == contributionOptions.contribution.value ){
                                        setFieldValue("contributionAmount", "")
                                        setFieldValue("totalCombinedContribution", 0);
                                        setFieldValue("employerContribution", 0)
                                    }
                                    setFieldValue('contributionType', value)
                                }}
                                value={values}
                        >
                            <ToggleButton
                                    disabled={atlantic}
                                    className={values.contributionType === contributionOptions.contribution.value ? "Mui-selected active" : ""}
                                    id={contributionOptions.contribution.id}
                                    value={contributionOptions.contribution.value}
                            >
                                {contributionOptions.contribution.label}
                            </ToggleButton>
                            <ToggleButton
                                    disabled={atlantic}
                                    className={values.contributionType === contributionOptions.deathBenefits.value ? "Mui-selected active" : ""}
                                    id={contributionOptions.deathBenefits.id}
                                    value={contributionOptions.deathBenefits.value}
                            >
                                {contributionOptions.deathBenefits.label}
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <ErrorHelper errors={errors} name={'contributionType'}/>
                    </Grid>

                    <Grid item xs={12}>
                        <FormInputs
                                fields={contributionTypeConditionalFields(values)}
                                {...{errors: {}, values, touched, handleChange, handleBlur}}
                        />
                        <ErrorHelper
                                errors={errors}
                                name={'deathBenefitAmount'}
                        />
                        <ErrorHelper
                                errors={errors}
                                name={'contributionAmount'}
                        />
                    </Grid>

                    {
                            (values.contributionType == contributionOptions.deathBenefits.value) &&
                                <Grid item xs={12}>
                                    <ContributionFrequency
                                            carrierId={carrierId}
                                            disabled={false}
                                            text={"How often will you be contributing (for 5 years)"}
                                    />
                                </Grid>
                    }

                    <Grid item xs={12}>
                        <Typography variant="caption" className="floatLeft w100 mb10">
                            Income Option:
                        </Typography>
                        <ToggleButtonGroup
                                aria-label="Platform"
                                color="primary"
                                exclusive
                                id="incomeOptions"
                                onChange={(_event: any, value: string) => setFieldValue('incomeOptions', value)}
                                value={values}
                        >
                            <ToggleButton
                                    className={values.incomeOptions === incomeOptions.income.value ? "Mui-selected active" : ""}
                                    id={incomeOptions.income.id}
                                    value={incomeOptions.income.value}
                            >
                                {incomeOptions.income.label}
                            </ToggleButton>
                            <ToggleButton
                                    className={values.incomeOptions === incomeOptions.deathBenefitsOnly.value ? "Mui-selected active" : ""}
                                    id={incomeOptions.deathBenefitsOnly.id}
                                    value={incomeOptions.deathBenefitsOnly.value}
                            >
                                {incomeOptions.deathBenefitsOnly.label}
                            </ToggleButton>
                        </ToggleButtonGroup>
                        <ErrorCommon
                                errors={errors}
                                name={'incomeOptions'}
                                touched={touched}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <FormInputs
                                    fields={incomeConditionalFields(values)}
                                    {...{errors, values, touched, handleChange, handleBlur}}
                            />
                        </Grid>
                    </Grid>
                    {(values["contributionType"] == contributionOptions.deathBenefits.value) && 
                        <Grid item xs={12}>
                            <NewEmployerContribution Tag="ADMIN_ACTION" clientDetails={userDetail.client} RadioFieldName={"contributionTypeToggle"} atlantic={atlantic} />
                        </Grid>
                    }
                    <StepFour callingFrom={"ADMIN_FINAL_ILLUSTRATION"}
                       userDetails={{ strategyId:(userDetail && userDetail.client &&  userDetail.client.strategyId),
                        carrierId: userDetail && userDetail.client && userDetail.client.carrierId, clientId: userDetail.client && userDetail.client.id }}
                    />
                    <Grid item xs={12} className="textCenter">
                        <Button
                                className="floatLeft mt15 mr15"
                                color="primary"
                                label='Upload Custom Illustration'
                                onClick={onUploadCustomIllustration}
                                size="small"
                                variant="contained"
                        />
                        <Button
                                className="floatLeft mt15 mr15"
                                color="primary"
                                label='Run New Illustration'
                                loading={isReportDataLoading}
                                onClick={onRunIllustration}
                                size="small"
                                variant="contained"
                                disabled={isReportDataLoading}
                        />
                    </Grid>
                    
                    {
                            uploadCustomOrRunIllustration === ProposalEnum.UPLOAD_CUSTOM_ILLUSTRATION &&
                            <>
                                {
                                    jVals.map((jp: any, index: number) => (
                                            <Grid item key={index} xs={12} sm={6} md={3}>
                                                {
                                                        jp.showOnProposalUpdate &&
                                                        <UploadCustomIllustration
                                                                enrollmentId={enrollmentId}
                                                                exactFileName={jp.exactFileName}
                                                                fn={jp.fileName}
                                                                name={jp.name}
                                                                setFN={jp.setFileName}
                                                        />
                                                }

                                                {
                                                        !!jp.valName &&
                                                        <ErrorCommon
                                                                errors={errors}
                                                                name={jp.valName}
                                                                touched={touched}
                                                        />
                                                }
                                            </Grid>
                                    ))
                                }

                                {
                                        jVals.find((jp: any) => !!jp.fileName) &&
                                        <UploadedDocumentTable
                                            reports={[...jVals].map(commonFun) as reportsInterFace[] }
                                            onRemove={(_: number, data: any)=>{ data.setFileName('')}}
                                        
                                        />
                                }
                            </>
                    }
                </Grid>
            </form>
    );
};

export default Form;
