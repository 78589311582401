import React, { useEffect } from "react";
import numeral from "numeral";
import FocusError from "../../../../../components/FocusError";
import {
    Box,
    Button,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    Tooltip,
    Typography,
} from "@material-ui/core";
import Citizenship from "./ProfileFormFields/Citizenship";
import { ErrorCommon } from "../../../../../components/formikFormInputs";
import { Label } from "../../../../../components/formInputs";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import NumberFormat from "react-number-format";
import InfoIcon from "@material-ui/icons/Info";
import { Formik } from "formik";
import { OTHER_VALUE, otherIndustryId, REQUIRED_FIELD, trustFeeText } from "../../../../../constants";
import PersonalIncomeDetailsContent from "./PersonalIncomeDetailsContent";
import IndividualIncome from "./ProfileFormFields/IndividualIncome";
import HouseHoldIncome from "./ProfileFormFields/HouseHoldIncome";
import CurrentEmployeeRadio from "./CurrentEmployeeRadio";
import { isAdmin } from "../../../../../components/navigation/AdminNavigation";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../reducers";
import { isAgent, noop } from "../../../../../utils/commonUtils";
import Socket from "../../../Estimation/V1/Socket";
import { getClientEstimationData, handleButtonClick } from "../../../Estimation/V1/Steppers";
import { useActions } from "../../../../../actions";
import * as Actions from "../../../../../actions/estimation";
import NonUSPopUp from "./ProfileFormFields/NonUSPopUp";
import PersonalDetailsDialog from "../component/EnrollmentHome/PersonalDetailsDialog";

type PropsType = {
	initialValues: any,
	personalDetailSchema: any,
	isCorporation: boolean,
	setOpenKickOutPopup: Function,
	setIsFormSubmit: Function,
	isFromClientDetail: boolean | undefined,
	proofExpired: any,
	isNationalLife: boolean,
	selfEmployed: boolean,
	minAge: any,
	setInitialValues: Function,
	setNextStep: Function,
	client: any,
	setActionItem: Function,
	role: any,
	setIsPersonalFormDirty: Function | undefined,
	setIsFormSubmitting: Function | undefined,
	setIncomeAlertOpen: Function,
	setValidationError: Function | undefined,
	setSelfEmployed: Function,
	accountActions: any,
	setOpenAlert: Function,
	user: any,
	fieldObj: any,
	clientSigned: boolean | number,
	conditionalRequired: boolean,
	setCurrentlyEmployed: Function,
	setFullTimeEmployee: Function
	fullTimeEmployee: any,
	CommonField: Function,
	classes: any,
	fieldObj1: any,
	fieldObj8: any,
	proofExpireRef: any,
	fieldObj4: any,
	fieldObj7: any,
	fieldObj9: any,
	fieldObj2: any,
	fieldObj3: any,
	strategySlug: string,
	fieldObj6: any,
	clientDetail: any,
	showClientContribution: boolean,
	showEmployerContribution: boolean,
	minVal: any,
	personalDetailSubmit: any,
	setFullTimeSelected: Function,
	fullTimeSelected: number,
	setIsDocumentAddress?: Function,
	setIsMarried: Function,
	setIsEmployed: Function,
	isAllianzLife: boolean,
	setIsParentEarner: Function,
	isParentEarner: boolean,
	personalDetailForm?: any,
	makePersonalDetailPayload?: Function,
	sendSaveRequest: Function,
	getClientDetail?: Function,
	urlClientId?: string,
	ssnFlag?: boolean,
	setServerError: Function,
	serverError: boolean
	setAnticipatedAge: Function
}
export const incomeDetailsColArr = [
	"indIncomeLastYearAnnualSalary", "indIncomeLastYearDividends",
	"indIncomeLastYearOtherIncome",
	"indIncomeLastYearTotal",
	"indIncome1YearsPriorAnnualSalary",
	"indIncome1YearsPriorDividends",
	"indIncome1YearsPriorOtherIncome",
	"indIncome1YearsPriorTotal",
	"indIncome2YearsPriorAnnualSalary",
	"indIncome2YearsPriorDividends",
	"indIncome2YearsPriorOtherIncome",
	"indIncome2YearsPriorTotal",
	"houseHoldIncomeLastYearAnnualSalary",
	"houseHoldIncomeLastYearDividends",
	"houseHoldIncomeLastYearOtherIncome",
	"houseHoldIncomeLastYearTotal",
	"houseHoldIncome1YearsPriorAnnualSalary",
	"houseHoldIncome1YearsPriorDividends",
	"houseHoldIncome1YearsPriorOtherIncome",
	"houseHoldIncome1YearsPriorTotal",
	"houseHoldIncome2YearsPriorAnnualSalary",
	"houseHoldIncome2YearsPriorDividends",
	"houseHoldIncome2YearsPriorOtherIncome",
	"houseHoldIncome2YearsPriorTotal"];

function PersonalDetailFormikContent(props: PropsType) {
	const {
		initialValues,
		personalDetailSchema,
		isCorporation,
		isFromClientDetail,
		proofExpired,
		minAge,
		setNextStep,
		client,
		setActionItem,
		role,
		setIsPersonalFormDirty,
		setSelfEmployed,
		setFullTimeEmployee,
		fullTimeEmployee,
		showClientContribution,
		setIsFormSubmitting,
		showEmployerContribution,
		setIncomeAlertOpen,
		setValidationError,
		accountActions,
		user,
		minVal,
		personalDetailSubmit,
		fieldObj,
		clientSigned,
		conditionalRequired,
		CommonField,
		fieldObj1,
		fieldObj8,
		fieldObj4,
		proofExpireRef,
		fieldObj7,
		fieldObj9,
		classes,
		fieldObj2,
		setCurrentlyEmployed,
		strategySlug,
		fieldObj3,
		fieldObj6,
		setFullTimeSelected,
		fullTimeSelected,
		setIsDocumentAddress,
		setIsMarried,
		setIsEmployed,
		isAllianzLife,
		setIsParentEarner,
		isParentEarner,
		isNationalLife,
		personalDetailForm,
		makePersonalDetailPayload,
		sendSaveRequest,
		getClientDetail,
		urlClientId,
		ssnFlag,
		setServerError,
		serverError,
		setAnticipatedAge,
        contributionAmount,
        setBasicInfoStep
	}: any = props;
	const authUser = useSelector((state: RootState) => state.auth.user);
	const id = user && user.client && user.client.userId;
	const callingFrom: string = "ENROLLMENT_TAB";
	const estimationActions = useActions(Actions);
	const [requestId, setRequestId] = React.useState<null | string>(null);
	const [isReportRequestAutomated, setIsReportRequestAutomated] = React.useState<boolean>(false);
    const [nonUSPopUp, setNonUSPopUp] = React.useState<boolean>(false);
    const [openPersonalDialog, SetOpenPersonalDialog] = React.useState(false);
    const [dobCheck, setDobCheck] = React.useState<boolean>(false);

	const userObj = {
		id, role,
	};
	const handleResult = () => {

	};

	const handleReset = () => {
		localStorage.removeItem("success");
		localStorage.removeItem("lastEstimatedTime");
		localStorage.removeItem("lifeDeathBenefitSeeking");
		// setShowResults(false);
	};
	useEffect(() => {
		if (user && user.client && user.client.userId) {
			getClientEstimationData({
				estimationActions,
				userId: user.client.userId,
				setResponseData: noop,
				setLifeDeathBenefitSeeking : noop,
				handleResult,
				handleReset,
				callingFrom,
			}).then(() => {
			});
		}
	}, []);

	const basicInfoQuestions = () => {
		return [
			{
				name: "USBasedCompany",
				heading: "Is your company a U.S. based company?"
			},
            {
                name: "highRanking",
                heading: "Entrusted with a high-ranking prominent political function?"
            },
			{
				name: "individualHighRanking",
				heading: "A relative or a close associate of an individual entrusted with a high-ranking prominent political function?"
			},
			{
				name: "workInIndustries",
				heading: "Do you operate or work in following industries?",
				subHeading: "Oil, gas, weapons, precious metals, minerals or precious stones, tobacco, cannabis, marijuana products, cultural goods or other articles of archaeological, historical, cultural or religious importance or of exceptional scientific value, including ivory and protected species."
			},
		];
	};

    const radioToggleButtonClassName = (filedName: string, values: string, status: any) => {
        let className: string = '';
        if ((filedName !== "USBasedCompany") && (values[filedName] === status)) {
            className = "active";
        } else if ((filedName !== "workInIndustries") && (values[filedName] === status)) {
            className = "active";
        } else if ((filedName === "USBasedCompany" || filedName === "workInIndustries") && (values[filedName] === status) && (values["currentlyEmployed"] !== "HOME_MAKER" || values["currentlyEmployed"] !== "STUDENT" || values["currentlyEmployed"] !== OTHER_VALUE)){
            className = "active";
        }
        return className;
    };

	const radioToggleButtonGroup = (filedName: string, values: any, setFieldValue: Function, heading: string, subHeading?: string) => {
        const buttonsPair: boolean = filedName === "USBasedCompany" || filedName === "workInIndustries";
        const disableFor: boolean = values["currentlyEmployed"] === "HOME_MAKER" || values["currentlyEmployed"] === "STUDENT" || values["currentlyEmployed"] === OTHER_VALUE;
		return (
			<>
				<Grid item xs={12} sm={12} md={9}>
                    <Typography variant="caption" className="floatLeft mt20 w100"> <Label
                            label={heading}
                            required={ buttonsPair && disableFor ? false : conditionalRequired} />
                    </Typography>
					{subHeading &&
						<Grid container>
							<Grid item xs={12}>
								<span className="f12 textLight">{subHeading}</span>
							</Grid>
						</Grid>
					}
				</Grid>
                <Grid item xs={12} sm={12} md={3}>
                    <Grid container>
                        <Grid item xs={12}>
                            <ToggleButtonGroup
                                    className="toggleButtonGroupQust floatRight"
                                    size="medium"
                                    color={"primary"}
                                    exclusive
                                    id={filedName}
                                    value={values[filedName] === "" ? "" : values[filedName] === 1 ? 1 : values[filedName] === 0 ? 0 : ""}
                                    onChange={(ev: any, value: any) => {
                                        if (value === 1) {
                                            setFieldValue(filedName, value);
                                        }
                                        if (value === 0) {
                                            setFieldValue(filedName, value);
                                        }
                                        if (value === null) {
                                            setFieldValue(filedName, "");
                                        }
                                        sendSaveRequest(Math.random());
                                    }}
                            >
                                <ToggleButton value={1}
                                              disabled={!!clientSigned || (buttonsPair && disableFor)}
                                              className={radioToggleButtonClassName(filedName, values, 1)}>
                                    Yes
                                </ToggleButton>
                                <ToggleButton value={0}
                                              disabled={!!clientSigned || (buttonsPair && disableFor)}
                                              className={radioToggleButtonClassName(filedName, values, 0)}>
                                    No
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
						<Grid item xs={12}>
                            <span className="floatRight">
                                    <RadioToggleButtonError filedName={filedName} values={values["currentlyEmployed"]} valuesFiledName={values[filedName]} buttonsPair={buttonsPair}/>
                            </span>
						</Grid>
					</Grid>
				</Grid>
			</>
		);
	};

	return (
		<>
			<div>
				<Socket
					handleResult={handleResult}
					setIsEstimationDataLoading={noop}
					setResponseData={noop}
					handleReset={handleReset}
					userId={id}
					page={"clientDetails"}
					setIsReportRequestCompleted={noop}
					setLifeDeathBenefitSeeking={() => {
					}}
					isReportRequestAutomated={isReportRequestAutomated}
					handleButtonClick={(loader) => handleButtonClick({
						loader,
						user: userObj,
						setIsEstimationDataLoading : noop,
						setIsReportRequestAutomated,
						setRequestId,
						setIsReportRequestCompleted : noop,
						estimationActions,
						setIsReportDataLoading:noop,
						callingFrom,
					})}
					setIsReportDataLoading={noop}
					requestId={requestId}
					callingFrom={callingFrom}
				/>
			</div>
			<Formik
				innerRef={personalDetailForm}
				initialValues={initialValues}
				enableReinitialize={true}
				validationSchema={personalDetailSchema}
				validateOnChange={true}
				onSubmit={async (values: any, { setSubmitting, setValues }) => {
					let payload: any = {};
					if (typeof makePersonalDetailPayload === "function") {
						payload = makePersonalDetailPayload(values, setValues);
					}

					if (!payload) {
						return false;
					}
					try {
						await accountActions.updateUserBasicDetails(payload, (client && client.userId) || 0);
						setTimeout(() => {
							if (!isFromClientDetail) {
								setNextStep();
							} else {
								setActionItem(
									(role == 1 || role == 2 || role == 4) ? true
										:
										(!!(user.enrollment && user.enrollment.clientSigned && values.usCitizen)),
								);
							}
							setSubmitting(false);
						}, 400);
					} catch (e) {
						if (e && e.message == 422 && (typeof getClientDetail === "function")) {
							getClientDetail(urlClientId || 0, ssnFlag);
							setServerError(true);
						}
						return false;
					}
				}}
			>
				{({
					  values,
					  errors,
					  touched,
					  handleChange,
					  setFieldValue,
					  handleBlur,
					  handleSubmit,
					  isSubmitting,
					  dirty,
                      validateForm,
                      setSubmitting,
                      setTouched,
                      setFieldError
				  }) => {
					if (typeof setIsPersonalFormDirty === "function") {
						setIsPersonalFormDirty(dirty);
					}
					if (typeof setIsFormSubmitting === "function") {
						setIsFormSubmitting(isSubmitting);
					}
					if (Object.keys(errors).length >= 1 && Object.keys(touched).length > 1) {
						let errArr = Object.keys(errors);
						let incomeArr = errArr.filter((item) => {
							if (typeof setIsPersonalFormDirty === "function" && item.indexOf("annualIncome") > -1) {
								setIsPersonalFormDirty(false);
							}
							return item.indexOf("annualIncome") > -1;
						});
						let touchArr = Object.keys(touched);
						let incomeTouchArr = touchArr.filter((item) => {
							return item.indexOf("annualIncome") > -1;
						});
						if (incomeArr.length > 1 && incomeTouchArr.length > 1) {
							setIncomeAlertOpen(true);
						} else {
							setIncomeAlertOpen(false);
						}
					}

					if (typeof setIsPersonalFormDirty === "function") {
						if (Object.keys(errors).length >= 1 && Object.keys(touched).length >= 1) {
							if (typeof setValidationError === "function") {
								setValidationError(true);
							}
						} else {
							if (typeof setValidationError === "function") {
								setValidationError(false);
							}
						}
						if (serverError && typeof setValidationError === "function") {
							setValidationError(true);
						}
					}

                    setDobCheck(!!errors['dob'] || (values['dob'] === "Invalid date"));
					return (<>

							<form className="mt20 floatLeft w100" onSubmit={handleSubmit} autoComplete="off">
								{
									!isAdmin(authUser) && !isAgent(authUser) &&
									<Typography variant="caption" className="mt15 mb15 colorRed">
										<sup>*</sup><strong>Please note</strong>: You must use your legal
										name that matches your drivers license.
									</Typography>
								}
								<FocusError offset={true} />
								<Grid container spacing={2}>
									{isFromClientDetail &&
										<Citizenship
											fieldObj={fieldObj}
											fieldObj4={fieldObj4}
											fieldObj7={fieldObj7}
											minAge={minAge}
											proofExpireRef={proofExpireRef}
											proofExpired={proofExpired}
											clientSigned={clientSigned}
											conditionalRequired={conditionalRequired}
											role={role}
											client={client}
											CommonField={CommonField}
											values={values}
											errors={errors}
											touched={touched}
											setFieldValue={setFieldValue}
											handleChange={handleChange}
											handleBlur={handleBlur}
											setAnticipatedAge={setAnticipatedAge}
                                            setNonUSPopUp={setNonUSPopUp}
                                            accountActions={accountActions}
										/>
									}

                                    {
                                            nonUSPopUp &&
                                            <NonUSPopUp
                                                    nonUSPopUp={nonUSPopUp}
                                                    setNonUSPopUp={setNonUSPopUp}
                                            />
                                    }

									{fieldObj1.map((field, index) => {
										return (
											<Grid item xs={12} md={6} xl={4} key={index + field["name"]}>
												<div className="mt20">
													{
														CommonField(
															field,
															{
																values,
																errors,
																touched,
																handleChange,
																handleBlur,
																setFieldValue,
															},
														)
													}
												</div>
											</Grid>
										);
									})
									}

									{isFromClientDetail && !values["usCitizen"] &&
										fieldObj8.map((field, index) => {
											return (
												<Grid item xs={12} md={12} xl={6}
													  key={index + field["name"]}>
													<>
														{
															CommonField(
																field,
																{
																	values,
																	errors,
																	touched,
																	handleChange,
																	handleBlur,
																	setFieldValue,
																},
															)
														}
													</>
												</Grid>
											);
										})

									}

									{!isFromClientDetail &&
										<Citizenship
											fieldObj={fieldObj}
											fieldObj4={fieldObj4}
											fieldObj7={fieldObj7}
											minAge={minAge}
											proofExpireRef={proofExpireRef}
											proofExpired={proofExpired}
											clientSigned={clientSigned}
											conditionalRequired={conditionalRequired}
											role={role}
											client={client}
											CommonField={CommonField}
											values={values}
											errors={errors}
											touched={touched}
											setFieldValue={setFieldValue}
											handleChange={handleChange}
											handleBlur={handleBlur}
											setAnticipatedAge={setAnticipatedAge}
                                            setNonUSPopUp={setNonUSPopUp}
                                            accountActions={accountActions}
											sendSaveRequest={sendSaveRequest}
										/>
									}

								</Grid>

								{!values["usCitizen"] &&
									<Grid container spacing={2} className={"mt30"}>
										{!isFromClientDetail && fieldObj8.map((field, index) => {
											return (
												<Grid item xs={12} md={12} xl={6}
													  key={index + field["name"]}>
													<>
														{
															CommonField(
																field,
																{
																	values,
																	errors,
																	touched,
																	handleChange,
																	handleBlur,
																	setFieldValue,
																},
															)
														}
													</>
												</Grid>
											);
										})
										}

										{fieldObj9.map((field: any, index) => {
											return (
												<Grid item xs={12} md={12} key={index + field["name"]}>
													<Typography variant="body1"
																className="floatLeft mt20 mb10">
														{field.label}
													</Typography>
													<RadioGroup
														id={field.name}
														className="mb0 checkGroup checkGroupEnrollment"
														name={field.name}
														onChange={ async (e) => {
															if (values.immigrationStatus) {
																setFieldValue("greenCardNo", "");
																setFieldValue("typeOfVisaPlanHeld", "");
																setFieldValue("typeOfVisaHeld", "");
																setFieldValue("immigrationStatusOther", "");
															}
															setFieldValue(field.name, e.target.value);
                                                            let payload: any = {};
                                                            payload[field.name] = e.target.value;
                                                            await accountActions.updateUserBasicDetails(payload, (client && client.userId) || 0, "");
														}}
														value={values[field.name]}
													>
														<Grid container spacing={2}>
															{field.options.map((option: any, idx: number) => (
																<React.Fragment
																	key={idx + option.name}>
																	<Grid item xs={12} md={12}
																		  className={classes.radioGroup}>
																		<FormControlLabel
																			className="mb0"
																			disabled={!!clientSigned}
																			value={option.value}
																			control={
																				<Radio
																					className="mb0 f18"
																					color="primary" />}
																			label={option.label} />
																	</Grid>
																	{(option.textField && ((values.immigrationStatus === option.value) || (values.residenceInHomeCountry === option.value))) &&
																		<Grid item xs={12}
																			  md={option.value === "1" || option.value === "OTHER" ? 12 : 6}>
																			{
																				CommonField(
																					option.textField,
																					{
																						values,
																						errors,
																						touched,
																						handleChange,
																						handleBlur,
																						setFieldValue,
																					},
																				)
																			}
																		</Grid>
																	}
																</React.Fragment>
															))}
														</Grid>
														{touched[field.name] && errors[field.name] && (
															<ErrorCommon errors={errors}
																		 name={field.name}
																		 touched={touched} />
														)}
													</RadioGroup>
												</Grid>
											);
										})
										}

									</Grid>
								}

								<span className="hLine mt30" />
								<Typography variant="h6" className="fontBold w100">Home Address</Typography>
								<Grid container spacing={2}>
									{fieldObj2.map((field, index) => {
										return (
											<Grid item xs={12} md={6} xl={4} key={index + field["name"]}>
												<div className="mt20">
													{
														CommonField(
															field,
															{
																values,
																errors,
																touched,
																handleChange,
																handleBlur,
																setFieldValue,
															},
														)
													}
												</div>
											</Grid>
										);
									})
									}

                                    <Grid item xs={12} md={12} xl={12} className="floatLeft w100">
                                        <Typography variant="caption" className="floatLeft mt20">
                                            <Label label={"Is this the home address displayed on your driver’s license?"}
                                                   required={conditionalRequired} />
                                        </Typography>
                                        <ToggleButtonGroup
                                            className="toggleButtonGroup" size="large"
                                            value={values["isDocumentAddress"]} exclusive
                                            id={"isDocumentAddress"}
                                            onChange={(e: any, val: any) => {
                                                if (typeof setIsDocumentAddress === "function")
                                                    setIsDocumentAddress(val);
                                                setFieldValue("isDocumentAddress", !values["isDocumentAddress"]);
                                                sendSaveRequest(Math.random());
                                            }}
                                        >
                                            <ToggleButton value={true}
                                                          disabled={!!clientSigned}
                                                          className={values["isDocumentAddress"] ? "active" : ""}>
                                                Yes
                                            </ToggleButton>
                                            <ToggleButton value={false}
                                                          disabled={!!clientSigned}
                                                          className={values["isDocumentAddress"] ? "" : "active"}>
                                                No
                                            </ToggleButton>
                                        </ToggleButtonGroup>
                                        {!values["isDocumentAddress"] && !isFromClientDetail &&
                                            <span className="f12 colorRed floatLeft w100 mt15">You will be required to upload utility docs to confirm your address.</span>
                                        }
                                    </Grid>

								</Grid>
								<span className="hLine" />
								<Typography variant="h6"
											className="w100 mb20">{!isFromClientDetail && "Basic "} Income
									Details</Typography>
								<Grid container spacing={2}>
                                    <Grid item xs={12} md={12} xl={12}>
                                        <PersonalIncomeDetailsContent clientSigned={clientSigned} values={values}
                                                                      conditionalRequired={conditionalRequired}
                                                                      touched={touched} errors={errors}
                                                                      classes={classes} CommonField={CommonField}
                                                                      handleChange={handleChange}
                                                                      handleBlur={handleBlur}
                                                                      setFieldValue={setFieldValue}
                                                                      setIsMarried={setIsMarried}
                                                                      sendSaveRequest={sendSaveRequest}
                                        />
                                    </Grid>
                                    {!(isCorporation) &&
										<Grid item xs={12} md={12} xl={12}>
											<CurrentEmployeeRadio
												classes={classes}
												clientSigned={clientSigned}
												conditionalRequired={conditionalRequired}
												isAllianzLife={isAllianzLife}
												isParentEarner={isParentEarner}
												sendSaveRequest={sendSaveRequest}
												setCurrentlyEmployed={setCurrentlyEmployed}
												setFieldValue={setFieldValue}
												setFullTimeEmployee={setFullTimeEmployee}
												setFullTimeSelected={setFullTimeSelected}
												setIsEmployed={setIsEmployed}
												setIsParentEarner={setIsParentEarner}
												setSelfEmployed={setSelfEmployed}
												touched={touched} errors={errors}
												values={values}
											/>

										</Grid>
									}

									{isCorporation && //!(strategySlug === "cashstream") &&
										<Grid item xs={12} md={12} xl={12}>
											<Typography variant="caption"
														className="floatLeft textBlueDark mt20 mb15"> <Label
												label={"Are you a full time employee?"}
												required={conditionalRequired} /> </Typography>

											<RadioGroup
												className="mb0 checkGroup checkGroupEnrollment"
												name={"fullTimeEmployee"}
												onChange={(e) => {
													setFieldValue("fullTimeEmployee", Number(e.target.value));
													setFullTimeEmployee(Number(e.target.value));
												}}
												value={fullTimeEmployee ? fullTimeEmployee : values["fullTimeEmployee"]}
												style={{ display: "inline" }}
											>
												<Grid container spacing={2}>
													{[
														{
															label: "Yes",
															value: 1,
														},
														{
															label: "No",
															value: 0,
														},
													].map((option: any, idx: number) => (
														<Grid
															key={idx}
															sm={4}
															md={2}
															className={classes.radioGroup}>
															<FormControlLabel
																className="mb0"
																disabled={!!clientSigned}
																value={option.value}
																control={
																	<Radio
																		className="mb0 ml15"
																		color="primary" />}
																label={option.label} />
														</Grid>

													))}
												</Grid>
											</RadioGroup>
											{touched["fullTimeEmployee"] && errors["fullTimeEmployee"] && (
												<ErrorCommon errors={errors}
															 name={"fullTimeEmployee"}
															 touched={touched} />
											)}

										</Grid>
									}

									{fieldObj3.map((field, index) => {

										if (
											(field["name"] === "annualIncome") ||
											field["name"] === "annualIncome1" ||
											field["name"] === "annualIncome2"
										) {
											return null;
										}
										if (fullTimeSelected && (field["name"] === "annualIncome1" || field["name"] === "annualIncome2")) {
											return null;
										}

										if ((!values["fullTimeEmployee"]) &&
											(
												field["name"] === "employer" ||
												field["name"] === "employedTime" ||
												field["name"] === "annualIncome" ||
												field["name"] === "annualIncome1" ||
												field["name"] === "annualIncome2" ||
												field["name"] === "netWorth" ||
												field["name"] === "occupation" ||
												field["name"] === "industryId" ||
												field["name"] === "otherIndustry" ||
												field["name"] === "occupationId" ||
												field["name"] === "otherOccupation" ||
												field["name"] === "occupationDetail" ||
                                                field["name"] === "employerAddress"

											)
										) {
											return null;
										} else if (values["industryId"] === otherIndustryId && field["name"] === "occupationId") {
											return null;
										} else if (values["industryId"] !== otherIndustryId && (field["name"] === "otherIndustry" ||
											field["name"] === "occupation" || field["name"] === "otherOccupation")) {
											return null;
                                        } else if (values["currentlyEmployed"] === OTHER_VALUE) {
                                            return null;
										} else {
											return (
												<>
													<Grid item xs={12} md={6} xl={6}
														  key={index + field["name"]}>
														<div className="">
															{
																CommonField(
																	field,
																	{
																		values,
																		errors,
																		touched,
																		handleChange,
																		handleBlur,
																		setFieldValue,
																	},
																)
															}
														</div>
													</Grid>
												</>

											);
										}

									})
									}

									{
										basicInfoQuestions().map((fieldVal: any) => {
											return (
												radioToggleButtonGroup(
                                                        fieldVal.name,
                                                        values,
                                                        setFieldValue,
                                                        fieldVal.heading,
                                                        fieldVal.subHeading
                                                )
											);
										})
									}

                                    {!isCorporation && <>
                                        <IndividualIncome conditionalRequired={conditionalRequired}
                                                          CommonField={CommonField}
                                                          handleChange={handleChange}
                                                          handleBlur={handleBlur}
                                                          setFieldValue={setFieldValue}
                                                          touched={touched} errors={errors}
                                                          values={values}
                                        />
                                        {
                                            <HouseHoldIncome CommonField={CommonField}
                                                             handleChange={handleChange}
                                                             handleBlur={handleBlur}
                                                             setFieldValue={setFieldValue}
                                                             touched={touched} errors={errors}
                                                             values={values}
                                            />
                                        }
                                        </>
                                    }
								</Grid>


								{(role === 1 || role === 2 || role === 4) &&
									<>
										<span className="hLine" />
										<Typography variant="h6" className="w100 mb20">Contribution
											Amount</Typography>
										<Grid container spacing={2}>
											{fieldObj6.map((field, index) => {
												if (props.clientDetail && (props.clientDetail.role === 5 || (props.clientDetail.role === 6 && numeral(client.employerContribution).value() < 1)) && field.name === "employerContribution") {
													return <React.Fragment> </React.Fragment>;
												}
												if (!showClientContribution && field["name"] === "contributionAmount") {
													return <React.Fragment> </React.Fragment>;
												}
												if (!showEmployerContribution && field["name"] === "employerContribution") {
													return <React.Fragment> </React.Fragment>;
												}
												return (<><Grid item xs={12} md={6} xl={4}
																key={index + field["name"]}>
														<div className="mt20 customFields">
															{
																CommonField(
																	field,
																	{
																		values,
																		errors,
																		touched,
																		handleChange,
																		handleBlur,
																		setFieldValue,
																	},
																)
															}
														</div>
														{field["name"] == "contributionAmount" && numeral(minVal).value() > 0 &&
															<span className="f12 textLight" style={{
																display: "flex",
																alignItems: "center",
															}}>
                                                                     <NumberFormat
																		 value={minVal}
																		 displayType={"text"}
																		 thousandSeparator={true} prefix={"$"}
																		 renderText={value =>
																			 <Box component="span">{value} </Box>}
																	 />
                                                                      minimum contribution (includes trust fees)
                                                                      <Tooltip arrow
																			   title={
																				   <React.Fragment>
																					   <Typography
																						   variant="caption"
																						   className="mb0">Annual
																						   contribution
																						   includes a <span
																							   className="textHighLight">{trustFeeText(client)}</span> trust
																						   fee
																						   per year
																						   for
																						   5
																						   years. Trust pays the life
																						   insurance premiums.
																						   Minimum
																						   contribution
																						   is <span
																							   className="textHighLight">
                                                                                               <NumberFormat
																								   value={minVal}
																								   displayType={"text"}
																								   thousandSeparator={true}
																								   prefix={"$"}
																								   renderText={value =>
																									   <Box
																										   component="span">{value} </Box>}
																							   /></span> with
																						   trust
																						   fees.</Typography>
																				   </React.Fragment>
																			   }
																	  >
                                                                        <IconButton aria-label="Info" color="primary">
                                                                            <InfoIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </span>
														}
													</Grid>
													</>
												);
											})
											}
										</Grid>
									</>
								}

								{isFromClientDetail && isNationalLife && <></>
								}
								{(role === 1 || role === 2 || role === 4) ?
									<div style={{
										display: "none",
									}}><Button type="submit" disabled={!dirty || isSubmitting}
											   ref={personalDetailSubmit}></Button></div> :
									<Button
										variant="contained"
										color="primary"
										className="floatRight mb30"
                                        disabled={(!!(clientSigned || proofExpired || dobCheck))}
                                        onClick={async()=> {
                                            setSubmitting(true);

                                            const errors = await validateForm();
                                            if (Object.keys(errors).length === 0) {
                                                await accountActions.getProfileDetails();
                                                SetOpenPersonalDialog(true);
                                            } else {
                                                setTouched(errors as any)
                                                setSubmitting(false);
                                            }
                                        }}
									>
										Continue
									</Button>
								}
                                {openPersonalDialog &&
                                        <PersonalDetailsDialog openPersonalDialog={openPersonalDialog}
                                                               SetOpenPersonalDialog={(flag: boolean) => SetOpenPersonalDialog(flag)}
                                                               contributionAmount={contributionAmount}
                                                               setBasicInfoStep={setBasicInfoStep} />
                                }

							</form>
						</>
					);
				}}
			</Formik>
		</>
	);
}

export default PersonalDetailFormikContent;


const RadioToggleButtonError = (props: { filedName: string, values: string, valuesFiledName: any, buttonsPair: boolean }) => {
    const errorHelperStyle: React.CSSProperties = { color: "#FF1744", fontSize: "10px", margin: "0" };
    const { filedName, values, valuesFiledName, buttonsPair } = props
    let error = false;

    if (valuesFiledName === "" || valuesFiledName === null) {
        error = true;
    }

    if (buttonsPair && (values === "HOME_MAKER" || values === "STUDENT" || values === OTHER_VALUE)) {
        error = false;
    }
    return (
            <>
                {
                        (error) &&
                        <Grid item xs={12}>
                            <FormHelperText style={errorHelperStyle}>
                                <span className="w100 floatLeft"> {REQUIRED_FIELD} </span>
                            </FormHelperText>
                        </Grid>
                }
            </>
    );
};