import React from "react";
import { Grid, MenuItem, Select, Typography } from "@material-ui/core";
import { Label } from "../../../../../../components/formInputs";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton";
import BirthDate from "./BirthDate";
import LicenceExpiry from "./LicenceExpiry";
import { forNonUsCitizen } from "../../../../../../constants";

const Citizenship = (props: any) => {
	const {
		fieldObj,
		fieldObj4,
		fieldObj7,
		minAge,
		proofExpireRef,
		proofExpired,
		clientSigned,
		conditionalRequired,
		role,
		client,
		CommonField,
		values,
		errors,
		touched,
		setFieldValue,
		handleChange,
		handleBlur,
		setAnticipatedAge,
        setNonUSPopUp,
        accountActions,
		sendSaveRequest
	} = props;

	return (
		<>
			<Grid item xs={12}>
				<span className="hLine" />
				<Typography variant="h6" className="fontBold w100 mb20">Citizenship Details</Typography>
			</Grid>
			<Grid item xs={12} md={6} xl={4}>
				<Typography variant="caption" className="floatLeft">
					<Label label={"Are you a U.S. citizen?"}
						   required={conditionalRequired} />
				</Typography>
				<ToggleButtonGroup id={"usCitizen"} className="toggleButtonGroup mb30" size="large" exclusive
								   value={values["usCitizen"]}
								   onChange={ async () => {
									   setFieldValue("usCitizen", !values["usCitizen"]);
									   setFieldValue("employeeSSN", "");
									   setFieldValue("howLongInUS", "");
									   setFieldValue("countryCitizenship", "");
									   setFieldValue("immigrationStatus", "");
									   setFieldValue("greenCardNo", "");
									   setFieldValue("typeOfVisaPlanHeld", "");
									   setFieldValue("typeOfVisaHeld", "");
									   setFieldValue("immigrationStatusOther", "");
                                       setFieldValue("otherCitizenship", "");
									   if (values["usCitizen"]) {
										   setNonUSPopUp(true);
									   }
                                       let payload: any = {};
                                       payload["usCitizen"] = !values["usCitizen"];
                                       await accountActions.updateUserBasicDetails(payload, (client && client.userId) || 0, "");
								   }}>
					<ToggleButton value={true} disabled={!!clientSigned}
								  className={values["usCitizen"] ? "active" : ""}>
						Yes
					</ToggleButton>
					<ToggleButton value={false} disabled={!!clientSigned}
								  className={values["usCitizen"] ? "" : "active"}>
						No
					</ToggleButton>
				</ToggleButtonGroup>
			</Grid>
			{fieldObj.map((field, index) => {
				if (role === 1 || role === 2 || role === 4) {
					if (field["name"] === "blotSSN" && !client.blotSSN) {
						return null;
					} else {
						if (field["name"] === "employeeSSN" && !(client && client.employeeSSN) && (client && client.blotSSN)) {
							return null;
						}
					}
				} else {
					if (!values["usCitizen"] && field["name"] === "blotSSN") {
						return null;
					}
					if (field["name"] === "blotSSN" && !clientSigned) {
						return null;
					}
					if (values["usCitizen"] && field["name"] === "employeeSSN" && clientSigned) {
						return null;
					}
				}

				return (
					<React.Fragment key={index}>

                        {field.name === "employeeSSN" &&
                                <Grid item xs={12} md={4}>
                                    <Select
                                            value={Number(values["nonUsCitizen"])}
                                            name="nonUsCitizen"
                                            variant="outlined"
                                            onChange={e => {
                                                setFieldValue("nonUsCitizen", Number(e.target.value));
                                            }}
                                            fullWidth
                                    >
                                        {
                                            forNonUsCitizen.map((o) =>
                                                    <MenuItem key={o.value} value={o.value}>
                                                        {o.text}
                                                    </MenuItem>)
                                        }
                                    </Select>
                                </Grid>
                        }

						{field.name === "birthCountry" &&
							<BirthDate
								fieldObj4={fieldObj4}
								minAge={minAge}
								CommonField={CommonField}
								values={values}
								errors={errors}
								touched={touched}
								setFieldValue={setFieldValue}
								handleChange={handleChange}
								handleBlur={handleBlur}
								setAnticipatedAge={setAnticipatedAge}
								clientSigned={!!clientSigned}
								sendSaveRequest={sendSaveRequest}
							/>
						}

						<Grid item xs={12}
							  md={6}
							  xl={3}
							  key={index + field["name"]}>
							<>
								{
									CommonField(
										field,
										{
											values,
											errors,
											touched,
											handleChange,
											handleBlur,
											setFieldValue,
										},
									)
								}
							</>
						</Grid>


						{field.name === "identityId" && <>
							<Grid item xs={12} ref={proofExpireRef} id="proofExpireRef">
								<Typography variant="caption" className="floatRight-">
									Expiry Date (must match drivers license)
								</Typography>
							</Grid>
							<Grid container spacing={2} style={{ marginLeft: 2, marginBottom: 20 }}>
								<LicenceExpiry
									fieldObj7={fieldObj7}
									proofExpireRef={proofExpireRef}
									proofExpired={proofExpired}
									CommonField={CommonField}
									values={values}
									errors={errors}
									touched={touched}
									setFieldValue={setFieldValue}
									handleChange={handleChange}
									handleBlur={handleBlur}
								/>
							</Grid>
						</>}
					</React.Fragment>
				);
			})
			}
		</>);
};
export default Citizenship;