import React from 'react';
import {Formik} from "formik";
import { Button, Grid, IconButton, Tooltip, Typography } from "@material-ui/core";
import FocusError from "../../../../../components/FocusError";
import {EnrollmentQuestionProps} from "../../../../../model/enrollment";
import Info from "@material-ui/icons/Info";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@material-ui/lab/ToggleButton/ToggleButton";

import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import ApplicationQuestionnaireAllianz from "./ApplicationQuestionnaireAllianz";
import {isLongForm} from "./Utilities";
import { RenderMedicalDetail } from "./MedicalAndFinancial/component/MedicalPageCheckBox";

type PropsType = {
    initialAnswer: any,
    validationSchema: any,
    offerType: string,
    setOpenKickOutPopup: Function,
    questionList: any,
    isFromClientDetail: boolean,
    setNextStep: Function,
    setInitialAnswer: Function,
    enrollmentActions: any,
    setIsQuestionFormDirty: Function | undefined,
    role: any,
    applicationQuestionSubmit: any, enrollment: any, client: any
    clientSigned: boolean,
    showComponent: Function, enrollmentId: string, checkIsError: Function, carrierValAlert: boolean | undefined,
    setEnrollmentId: Function, setActionItem: Function | undefined, onChangeOperationForQuestionAns: Function,
    user?: any,
    applicationQuestions?:any,
    applicationQuestionPayload?: Function,
    sendSaveRequest: Function,
    carrierName?: string,
    errorInPhysicianAndMedicalDetails?:boolean
}

const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            medicalQuestionsXT_36: {
                display: "block",
                float: "right",
                textAlign:'right'
            },
        }),
);

function ApplicationQuestionnaireFormV2Content(props: PropsType){
    const {
        initialAnswer,
        validationSchema,
        offerType,
        setOpenKickOutPopup,
        questionList,
        isFromClientDetail,
        setNextStep,
        setInitialAnswer,
        enrollmentActions,
        setIsQuestionFormDirty,
        role,
        applicationQuestionSubmit, enrollment,
        clientSigned, enrollmentId, checkIsError, showComponent,
        client, setEnrollmentId, carrierValAlert, setActionItem, onChangeOperationForQuestionAns,
        user,
        applicationQuestions,
        applicationQuestionPayload,
        sendSaveRequest,
        carrierName,
        errorInPhysicianAndMedicalDetails
    } = props;
    const classes = useStyles();
    return (
            <Formik
                    innerRef={applicationQuestions}
                    initialValues={initialAnswer}
                    enableReinitialize={true}
                    validateOnBlur={false}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting }) => {
                        setSubmitting(true);
                        let payload:any = {};
                        if(offerType === "gi"){
                            if(values["Proposed_Insured_Questions-10"] === "No" || values["Proposed_Insured_Questions-26"] === "Yes"){
                                setOpenKickOutPopup(true);
                                setSubmitting(false);
                                return false;
                            }
                        }
                        if(offerType === "si"){
                            for(let qKey = 8; qKey <= 25; qKey++){
                                if(qKey !== 10 && values["Proposed_Insured_Questions-"+qKey] === "Yes"){
                                    setOpenKickOutPopup(true);
                                    setSubmitting(false);
                                    return false;
                                }
                                if(qKey === 10 && values["Proposed_Insured_Questions-"+qKey] === "No"){
                                    setOpenKickOutPopup(true);
                                    setSubmitting(false);
                                    return false;
                                }
                            }
                        }
                        /* Delete form payload form1480 Start */
                        if(values && values["Proposed_Insured_Questions-34"] === "No"){
                            delete values["travelCountry"];
                            delete values["travelPlanDuration"];
                            delete values["travelTripPurpose"];
                            delete values["travelAboutCountry"];
                            delete values["travelAbroadBefore"];
                            delete values["travelWhichCountries"];
                        }
                        /* Delete form payload form1480FieldArray Ends */

                        let isError: boolean = false;
                        questionList.forEach((field) => {
                            if (field.additionalFieldType === "checkbox" && values[(field && field.name) || ""] === "Yes" && values[(field && field.additionalFieldName) || ""] && values[(field && field.additionalFieldName) || ""].length === 0) {
                                isError = true;
                            }
                            if (values[(field && field.name) || ""] === "Yes" && field.additionalSubFieldType && field.additionalSubFieldType === "textfield" && checkIsError(values[(field && field.additionalSubFieldName) || ""], values[(field && field.additionalFieldName) || ""], values[(field && field.name) || ""])) {
                                isError = true;
                            }
                        });
                        if (isError && !isFromClientDetail) {
                            setSubmitting(false);
                            return false;
                        }
                        if(!errorInPhysicianAndMedicalDetails){
                            setNextStep();
                        }
                        setInitialAnswer(values);
                        if(typeof applicationQuestionPayload === 'function'){
                            payload = applicationQuestionPayload(values, !!errorInPhysicianAndMedicalDetails);
                        }
                        if(payload) {
                            setTimeout(() => {
                                setSubmitting(false);
                            }, 400);
                        }
                    }}
            >
                {
                    ({
                         values,
                         errors,
                         handleChange,
                         setFieldTouched,
                         setFieldValue,
                         handleBlur,
                         handleSubmit,
                         isSubmitting,
                         touched,
                         dirty
                     }) => {
                        if (typeof setIsQuestionFormDirty === "function") {
                            setIsQuestionFormDirty(dirty);
                            if (Object.keys(errors).length > 0) {
                                setIsQuestionFormDirty(!dirty);
                            }
                        }

                        return (
                                <form onSubmit={handleSubmit}>
                                    <FocusError offset={true}/>
                                    {questionList.map((question: EnrollmentQuestionProps, idx: number) => (
                                            <Grid item xs={12} sm={12} md={12} key={question.key || idx}>

                                                <Grid container>
                                                    {!!question.mainLabel &&
                                                    <Grid item xs={12} sm={12} md={12}>
                                                        <Typography  component="strong" variant="body1"
                                                                     className="mb0- floatLeft">{question.mainLabel}</Typography>
                                                    </Grid>
                                                    }
                                                    <Grid item xs={12} sm={12} md={9}>

                                                        <Typography style={{display:'flex'}} component="strong" variant="body1"
                                                                    className="mb0 floatLeft">
                                                            <React.Fragment>
                                                                {!!question.subQuestionLi &&
                                                                <span style={{marginRight:3}}>{question.subQuestionLi} </span>
                                                                }
                                                                <span>
                                                                            {question.label}
                                                                        </span>
                                                            </React.Fragment>
                                                        </Typography>
                                                        {question.infoTooltip &&
                                                        <Tooltip title={<Typography
                                                                className="f16 txtWhite mb0"> {question.infoTooltip} </Typography>}>
                                                            <IconButton>
                                                                <Info color="primary"/>
                                                            </IconButton>
                                                        </Tooltip>
                                                        }

                                                        {!!question.label1 &&
                                                        <Typography component="strong" style={{display:'flex'}} variant="body1"
                                                                    className="mb0 floatLeft">{question.label1}</Typography>
                                                        }
                                                        {!!question.label2 &&
                                                        <Typography component="strong" style={{display:'flex'}} variant="body1"
                                                                    className="mb0 floatLeft ml15"><span style={{marginRight:3}}>a.</span> <span> {question.label2}</span></Typography>
                                                        }
                                                        {!!question.label3 &&
                                                        <Typography component="strong" style={{display:'flex'}} variant="body1"
                                                                    className="mb0 floatLeft ml15"><span style={{marginRight:3}}>b.</span> <span> {question.label3}</span></Typography>
                                                        }

                                                    </Grid>

                                                    <Grid item xs={12} sm={12} md={3}>
                                                        <ToggleButtonGroup
                                                                className={classes.medicalQuestionsXT_36 + " floatRight "}
                                                                size="medium"
                                                                color={"primary"}
                                                                exclusive
                                                                id={question.name}
                                                                value={values[question.name] === "Yes" ? "Yes" : (values[question.name] == undefined || values[question.name] == "") ? "" : "No"}
                                                                onChange={async (ev: any, value: any) => {
                                                                    if (value === "Yes") {
                                                                        await setFieldValue(question.name, value);
                                                                    }
                                                                    if (value === "No") {
                                                                        await setFieldValue(question.name, value);
                                                                        if(question && question.resetFields && question.resetFields.length > 0) {
                                                                            for(let rf in question.resetFields){
                                                                                await setFieldValue(question.resetFields[rf].name, question.resetFields[rf].value)
                                                                            }
                                                                        }
                                                                    }
                                                                    if (value === null) {
                                                                        await setFieldValue(question.name, undefined);
                                                                    }
                                                                    onChangeOperationForQuestionAns(value, values, question);
                                                                    if(typeof sendSaveRequest === "function"){
                                                                        sendSaveRequest(Math.random());
                                                                    }

                                                                }}
                                                        >
                                                            <ToggleButton value={"Yes"}
                                                                          disabled={(role <= 4 || clientSigned) ? true : false}
                                                                          className={values[question.name] === "Yes" ? "active" : ""}>
                                                                Yes
                                                            </ToggleButton>
                                                            <ToggleButton value={"No"}
                                                                          disabled={(role <= 4 || clientSigned) ? true : false}
                                                                          className={values[question.name] === "No" ? "active" : ""}>
                                                                No
                                                            </ToggleButton>
                                                            {errors[question.name] && (
                                                                            <span className={"colorRed w100 fSize10 floatLeft pr0 pl0"}>Required Field</span>
                                                            )}
                                                        </ToggleButtonGroup>
                                                    </Grid>
                                                    {question && question.additionalRemarks &&
                                                    showComponent(question, values, touched, errors,
                                                            setFieldTouched, setFieldValue, handleChange,
                                                            handleBlur, clientSigned)
                                                    }

                                                    {
                                                            values[question.name] === "Yes" && isLongForm(offerType) &&
                                                            <RenderMedicalDetail
                                                                    clientSigned={clientSigned}
                                                                    errors={errors}
                                                                    fieldName={`${question.name}XT`}
                                                                    setFieldValue={setFieldValue}
                                                                    sendSaveRequest={sendSaveRequest}
                                                            />
                                                    }
                                                </Grid>
                                                {
                                                        !question.notShowHrLine &&
                                                        <span className="hLine" />
                                                }
                                                {
                                                    !!question.notShowHrLine &&
                                                        <p />
                                                }
                                            </Grid>
                                    ))}

                                    {
                                            isLongForm(offerType) &&
                                            <ApplicationQuestionnaireAllianz
                                                    clientSigned={clientSigned}
                                                    offerType={offerType}
                                                    sendSaveRequest={sendSaveRequest}
                                            />
                                    }

                                    {(role === 1 || role === 2 || role === 4 || role === 5 || role === 6) ?
                                        <div style={{
                                            display: "none",
                                        }}><Button type="submit" className={"detailPageSubmitBtn"}
                                                   disabled={isSubmitting}
                                                   ref={applicationQuestionSubmit}></Button>
                                        </div> : <Button
                                                variant="contained"
                                                color="primary"
                                                className="floatRight mb30"
                                                disabled={isSubmitting || (clientSigned ? true : false)}
                                                type={"submit"}
                                        >
                                            Save and Continue
                                        </Button>
                                    }
                                </form>
                        );
                    }}

            </Formik>
    )
}

export default ApplicationQuestionnaireFormV2Content;