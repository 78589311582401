import React from "react";
import {useFormikContext} from "formik";
import PaymentFileUploader from "./PaymentFileUploader";
import {usePolicyContext} from "./PolicyContext";
import PaymentFileViewer from "./PaymentFileViewer";
import { Grid } from "@material-ui/core";
import { cssPaths } from "../../../constants";
import {TO_BE_VOIDED} from "../../../constants";

function PaymentDocument(props: any) {
    const {fieldName} = props;
    const fileType = "image/jpg, image/jpeg, image/png, application/pdf";
    const {policy} = usePolicyContext();
    const {values, setFieldValue}: any = useFormikContext()
    const policyNumber = policy && policy.policyNumber;
    const setPaymentDocumentPath = (filePath: string) => {
        setFieldValue(fieldName, filePath);
    };
    return (<>

        {values.paymentDocumentPath === "" && <>
            <PaymentFileUploader
                    isSecuredSignedUrl={true}
                    fileType={fileType}
                    dirName={policyNumber}
                    fileName={`${TO_BE_VOIDED}ManualPaymentDocument`}
                    setFileName={setPaymentDocumentPath}
                    title="Upload payment document"
            />
        </>
        }
        {values[fieldName] &&
                <Grid item xs={12} md={3}>
                    <PaymentFileViewer
                            fileName={values[fieldName]}
                            policyNumber={policyNumber}
                            title={"Payment Document"}
                            readOnly={false}
                    />
                </Grid>
        }
    </>);

}

export default PaymentDocument;